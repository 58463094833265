@import "../../styles/_chunk";

.ProductFinder {
  position: relative;
  z-index: 10;
  margin: 2rem 0 3.5rem 0;

  .ProductFinder__searchButton {
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 700;
  }

  .ProductFinder__label {
    font-weight: 400;

    &--search {
      @include brand-property(color, brand-core);

      font-weight: 400;
      text-align: center;

      @include media-breakpoint-up(md) {
        text-align: right;
      }
    }

    &--hidden {
      opacity: 0;
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
      }
    }
  }

  .ProductFinder__content {
    padding-top: 3rem;
    position: relative;
    z-index: 1;

    @include media-breakpoint-up(md) {
      padding-top: 4rem;
    }

    .ProductFinder__header {
      margin-bottom: 1.5rem;
      color: $text-white;
      padding: 0 0.25rem;

      @include theme(light) {
        color: $white;
      }

      @include media-breakpoint-up(sm) {
        padding: 0;
      }

      @include media-breakpoint-up(md) {
        margin-bottom: 2rem;
      }

      p {
        font-weight: 400;

        @include media-breakpoint-up(md) {
          font-size: 16px;
        }
      }
    }

    .ProductFinder__filterBox {
      background-color: map-get($dark-theme, card-background);
      box-shadow: map-get($light-theme, card-box-shadow);
      border-radius: $border-radius;
      padding: 1.25rem;

      @include theme(light) {
        background-color: map-get($light-theme, card-background);
      }

      @include media-breakpoint-up(sm) {
        padding: 1.5rem;
      }

      @include media-breakpoint-up(md) {
        padding: 1.75rem;
      }

      @include media-breakpoint-up(lg) {
        padding: 2rem;
      }

      @include media-breakpoint-up(xl) {
        padding: 2.25rem;
      }

      @include media-breakpoint-up(xxl) {
        padding: 2.5rem;
      }

      .ProductFinder__filterBox__columns {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-left: -1rem;
        margin-right: -1rem;

        @include media-breakpoint-up(md) {
          flex-direction: row;
          margin-left: -0.75rem;
          margin-right: -0.75rem;
        }

        @include media-breakpoint-up(xl) {
          margin-left: -1rem;
          margin-right: -1rem;
        }

        .ProductFinder__filterBox__column {
          width: 100%;
          padding: 0 1rem;
          margin-bottom: 0.5rem;

          &:last-of-type {
            margin-bottom: 0;
          }

          @include media-breakpoint-up(md) {
            width: 50%;
            padding: 0 0.75rem;
          }

          @include media-breakpoint-up(lg) {
            width: 25%;
          }

          @include media-breakpoint-up(xl) {
            padding: 0 1rem;
          }

          select {
            width: 100%;
          }
        }
      }
    }
  }

  .ProductFinder__background {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $black;
    height: 250px;
    width: 100%;

    @include theme(light) {
      background-color: $white;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.75;

      @include theme(light) {
        opacity: 1;
      }
    }
  }

  &--onSale {
    margin: 0 0 4rem 0;

    @include media-breakpoint-up(xl) {
      margin: 0 0 5rem 0;
    }
  }
}
