@import "../../styles/_chunk";

.SearchableDropdown {
  width: 100%;
  position: relative;

  .SearchableDropdown__label {
    font-weight: 400;
  }

  .SearchableDropdown__button {
    text-transform: capitalize;
    width: 100%;
    height: 40px;
    text-align: left;
    background-color: $white;
    color: $text-black;
    padding: 10px;
    font-size: 0.875rem;
    border-radius: $border-radius-sm;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image:
      linear-gradient(45deg, transparent 50%, $grey-10 50%),
      linear-gradient(135deg, $grey-10 50%, transparent 50%),
      linear-gradient(to right, $white, $white);
    background-position:
      calc(100% - 14px) calc(1em + 4px),
      calc(100% - 8px) calc(1em + 4px),
      calc(100% - 2.5em) 0.5em;
    background-size:
      6px 6px,
      6px 6px,
      1px 1.5rem;
    background-repeat: no-repeat;
    border: 1px solid map-get($light-theme, input-border-color);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--clear {
      background-image: none;
    }
  }

  .SearchableDropdown__options {
    position: absolute;
    z-index: 1;
    left: 0;
    min-width: 240px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: $border-radius-sm;
    box-shadow: map-get($light-theme, card-box-shadow);
    overflow: hidden;
    padding: 0.5rem 0;
    border: 1px solid map-get($light-theme, input-border-color);
    color: $text-black;
    margin-top: 0.125rem;

    .SearchableDropdown__option {
      text-align: left;
      border: 0;
      padding: 0.25rem 1rem;
      background-color: $white;
      font-size: 0.875rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:hover {
        background-color: $grey-1;
      }

      .SearchableDropdown__option__count {
        font-size: 0.625rem;
        background-color: $grey-3;
        border-radius: 3px;
        padding: 0.125rem 0.375rem;
      }
    }

    .SearchableDropdown__options__divider {
      height: 0;
      margin: 0.5rem 0;
      overflow: hidden;
      border-top: 1px solid map-get($light-theme, input-border-color);
    }

    .SearchableDropdown__search {
      padding: 0.25rem 1rem;

      .input-group {
        border: 1px solid map-get($light-theme, input-border-color);
        border-radius: $border-radius-sm;

        input.form-control {
          border: 0;

          &:focus {
            box-shadow: none;
          }
        }

        .input-group-append {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 34px;
          color: $grey-3;
        }
      }
    }
  }
}
